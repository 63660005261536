﻿// declare globals
declare const fbq: (...args: any[]) => void;

export function configure(logEnabled: boolean = false): void {
    this.logEnabled = logEnabled;

    var n: any;
    var t: any;
    var s: any;

    const load = (f, b, e, v, n, t, s) => {
        if (f.fbq) {
            return;
        }

        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };

        if (!f._fbq) {
            f._fbq = n;
        }

        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];

        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    };
    load(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', n, t, s);

    if (this.logEnabled) {
        console.info(`[Facebook] fbevents.js loded`);
    }
}

export function add(pixelId: string): void {
    fbq('init', pixelId);

    if (this.logEnabled) {
        console.info(`[Facebook] browser init ${pixelId}`);
    }
}

export function trackSingleEvent(pixelId: string, event: string, eventId: string): void {
    fbq('trackSingle', pixelId, event, {}, { eventID: eventId });

    if (this.logEnabled) {
        console.info(`[Facebook] browser trackSingle ${pixelId} -> ${event} (id ${eventId})`);
    }
}

export function trackForAllEvent(event: string, eventId: string): void {
    fbq('track', event, {}, { eventID: eventId });

    if (this.logEnabled) {
        console.info(`[Facebook] browser track all initialized pixels -> ${event} (id ${eventId})`);
    }
}

export function trackForAllPurchaseEvent(purchaseData: { currency: string, value: number }, eventId: string): void {
    fbq('track', 'Purchase', purchaseData, { eventID: eventId });

    if (this.logEnabled) {
        console.info(`[Facebook] browser track all initialized pixels -> Purchase with data ${JSON.stringify(purchaseData)} (id ${eventId})`);
    }
}
