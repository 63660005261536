﻿// declare globals
declare const ga: (...args: any[]) => void;

export function configure(trackingId: string, debug: boolean = false): void {
    this.debug = debug;

    (function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('create', trackingId, 'auto');

    if (this.debug) {
        console.log(`[GAnalytics][${trackingId}] Configured!`);
    }
}