﻿import * as Logger from "../Scripts/Logger";
import * as FacebookPixel from "../FacebookPixel/FacebookPixelInterop";
import * as GoogleAnalytics from "../GoogleAnalytics/GoogleAnalyticsInterop";
import * as GoogleTagManager from "../GoogleTagManager/GoogleTagManagerInterop";
import * as GoogleAnalytics4 from "../GoogleAnalytics4/GoogleAnalytics4Interop";

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
        Tracking: any;
    }
}

window.Tracking = {
    Logger,
    FacebookPixel,
    GoogleAnalytics,
    GoogleAnalytics4,
    GoogleTagManager,
};

