﻿// declare globals
declare const dataLayer: any[];
declare const gtag: (...args: any[]) => void;

window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function () { dataLayer.push(arguments); };

export function configure(measurementId: string, logEnabled: boolean = false): void {
    this.logEnabled = logEnabled;

    //Import gtag script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    gtag('js', new Date());

    gtag('config', measurementId);

    if (this.logEnabled) {
        console.log(`[GA4][${measurementId}] Configured!`);
    }
}

export async function getGA4DataFromCookies(measurementId: string, logEnabled: boolean = false): Promise<Ga4Data> {
    
    return new Promise((resolve, reject) => {
        let ga4Data: Ga4Data = new Ga4Data();

        const sessionIdProm: Promise<string> = new Promise(resolve => { gtag('get', measurementId, 'session_id', resolve) });
        const sessionNumProm: Promise<string> = new Promise(resolve => { gtag('get', measurementId, 'session_number', resolve) });
        const clientIdProm: Promise<string> = new Promise(resolve => { gtag('get', measurementId, 'client_id', resolve) });

        const allProm = Promise.all([sessionIdProm, sessionNumProm, clientIdProm]);

        allProm.then((result) => {
            //Ritorna i valori delle promise nell'array values[] in ordine
            ga4Data.sessionId = result[0];
            ga4Data.sessionNum = result[1]? result[1].toString() : null;
            ga4Data.clientId = result[2];

            resolve(ga4Data);
        })
            .catch(error => {
                console.log('Error getting ga4 data from GA4: ', error);
                resolve(null);
            });
    });
}

class Ga4Data {
    sessionId: string;
    sessionNum: string;
    clientId: string;
}