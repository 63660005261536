﻿// declare globals
declare const dataLayer: any[];
declare const gtag: (...args: any[]) => void;

// init globals
window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function () { dataLayer.push(arguments); };


export function configure(trackingId: string, debug: boolean = false): void {
    this.debug = debug;

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    // configure first timestamp
    gtag("js", new Date());

    gtag("config", trackingId);

    if (this.debug) {
        console.log(`[GTAG][${trackingId}] Configured!`);
    }
}

export function trackEvent(event: string, eventCategory: string, eventLabel: string, eventValue: string) {
    gtag("event", event, { event_category: eventCategory, event_label: eventLabel, value: eventValue });

    if (this.debug) {
        console.log(`[GTAG][Event trigered]: ${event}`);
    }
}